import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Machines } from "../../../classes/Machine";
import { Col, Container, Row } from "react-bootstrap";
import { WmkLink } from "wmk-link";
import EquipPreview from "./EquipPreview";
import {
  CategoryListItem,
  getCategoryList,
  getCategoryListItems
} from "./logic";
import styled from "styled-components";
import { MachinesQuery } from "../../../fragments/NodeMachinesFields";

const CatLink = styled(Col)`
  a {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      color: rgba(0, 0, 0, 0.75);
      transition: all 0.3s ease;
    }
  }
`;

const EquipmentList = ({ currentCat = "all" }) => {
  const data: EquipListQuery = useStaticQuery(query);
  const machines = new Machines(data.m.edges);
  const catList = getCategoryList(machines);
  // ===== sort catList alphabetically =====
  // simple compare function to be fed to the javascript sort method - required because we are sorting an array of objects so we need to specifically target the 'title' key when sorting
  const compare = (a: CategoryListItem, b: CategoryListItem) => {
    if (a.title < b.title) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    } else {
      return 0;
    }
  };
  // remove the All cats entry (always first entry in catList due to the way that getCategoryList is set up) in case there's ever a category name that is alphabetically before "all"
  const allCat = catList.shift();
  // sort the rest of the categories alphabetically
  catList.sort(compare);
  // add the All cats entry back to the beginning of the array
  catList.unshift(allCat);
  const currentList = getCategoryListItems(catList, currentCat);
  return (
    <Container style={{ marginBottom: "4vh", marginTop: "10vh" }}>
      <Row style={{ marginTop: "4vh", marginBottom: "2vh" }}>
        {Array.isArray(catList) ? (
          catList.map((cat, i) => {
            const slug = cat.slug;
            const title = cat.title;
            return (
              <CatLink key={slug + i} xs="auto">
                {currentCat !== slug ? (
                  <WmkLink
                    to={`/machines-and-solutions/${
                      slug === "all" ? "" : slug
                    }`}>
                    {title}
                  </WmkLink>
                ) : (
                  <strong style={{ textTransform: "uppercase" }}>
                    {title}
                  </strong>
                )}
              </CatLink>
            );
          })
        ) : (
          <></>
        )}
      </Row>
      <Row>
        {currentList.map((m, i) => {
          const link = m.link;
          return (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={3}
              key={link.to + i}
              style={{ margin: `.5rem 0` }}>
              <EquipPreview
                title={m.displayTitle}
                image={m.thumbnails[0]}
                apps={m.applications}
                link={link}
                related
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default EquipmentList;

interface EquipListQuery {
  m: {
    edges: {
      node: MachinesQuery;
    }[];
  };
}

const query = graphql`
  {
    m: allContentfulMachines {
      edges {
        node {
          ...NodeMachinesFields
        }
      }
    }
  }
`;
