import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/MainLayout";
import { Page } from "../classes/Page";
import InnovationHero from "../components/one-off/InnovationHero/InnovationHero";
import ContactPage from "../components/one-off/ContactPage/ContactPage";
import GradientAngleHeader from "../components/layout/GradientAngleHeader";
import { useState } from "react";
import ServicesHeroNav from "../components/one-off/Services/ServicesHeroNav/ServicesHeroNav";
import EquipmentList from "../components/ui/Equipment/EquipmentList";
import ManualsTable from "../components/one-off/ManualsTable/ManualsTable";
import { WmkSeo } from "wmk-seo";
import { PageQuery } from "../fragments/NodePageFields";
import { BlockImageCopyQuery } from "../fragments/NodeRichTextFields";
import { MetaDataQuery } from "../fragments/NodeSiteMetaFields";
import AllBlogs from "../components/Blog/AllBlogs";
import { RichText, RichTextReact } from "wmk-rich-text";
import styled from "styled-components";
import { options } from "../components/RichText/richTextOptions";
import { RichTextDocument } from "wmk-rich-text";

export interface PageData extends PageQuery, BlockImageCopyQuery {}

const RichTextWrap = styled.div<{ space?: number }>``;

const addSpaceToContent = (content: RichText, space = 200) => {
  const { json }: { json: RichTextDocument } = content;
  const firstContent = json?.content[0];
  if (!firstContent) {
    return content;
  }
  // customization page has paragraph node come in first so I added specifics for that to render correctly
  if (
    typeof firstContent === "object" &&
    firstContent.nodeType === "embedded-entry-block"
  ) {
    json.content[0]["reference"] = { __typename: "", data: {} };
    json.content[0].reference.data.space = space;
  } else if (
    typeof firstContent === "object" &&
    json.content[1]?.nodeType === "embedded-entry-block"
  ) {
    json.content[1]["reference"] = { __typename: "", data: {} };
    json.content[1].reference.data.space = space;
  }
  return { ...content };
};

const PageNode = ({
  data
}: {
  data: { page: PageData; site: MetaDataQuery };
}) => {
  const [innerHeight, setInnerHeight] = useState(0);
  const page = new Page(data.page);
  const slug = page.slug;
  const path = page.path;
  const NullComponent = () => <></>;
  let BeforeComponent = () => (
    <GradientAngleHeader
      height={innerHeight}
      setter={setInnerHeight}
      white
      title={page.title}
      text={page.header?.text}
    />
  );
  let AfterHeaderComponent = NullComponent;
  let AfterComponent = NullComponent;
  switch (slug) {
    case `innovation`:
      BeforeComponent = () => <InnovationHero page={page} />;
      break;
    case `services`:
      AfterHeaderComponent = () => <ServicesHeroNav />;
      break;
    case `contact`:
      BeforeComponent = () => <ContactPage page={page} />;
      break;
    case `machines-and-solutions`:
      AfterComponent = () => <EquipmentList />;
      break;
    case `resources`:
      AfterHeaderComponent = () => <ManualsTable />;
      break;
    case `blog`:
      AfterHeaderComponent = () => <AllBlogs />;
      break;
    default:
  }
  const metaData = data.site.meta;
  const pageContent = addSpaceToContent(page.content, innerHeight);
  return (
    <Layout>
      <WmkSeo.Meta
        title={page.title}
        siteTitle={metaData.title}
        baseUrl={metaData.baseUrl}
        path={path === "/" ? "" : path}
        slug={page.slug}
        description={metaData.description}
        twitterHandle={
          "twitterHandle" in metaData ? metaData.twitterHandle : null
        }
        ogImageUrl={page.image?.url("ogImage")}
        twitterImageUrl={page.image?.url("twitterImage")}
      />
      <BeforeComponent />
      <AfterHeaderComponent />
      <RichTextWrap className="rt-wrap">
        <RichTextReact content={pageContent} options={options} />
      </RichTextWrap>
      <AfterComponent />
    </Layout>
  );
};

export default PageNode;

export const query = graphql`
  query pageQuery($slug: String) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...NodePageFields
      ...PageBlockImageCopy
    }
    site {
      ...NodeSiteMetaFields
    }
  }
`;
