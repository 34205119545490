import { Machine, Machines } from "../../../classes/Machine";
import { Tag } from "wmk-tag";

export const getFinalTag = (key: string, machinesByCategory: Machine[]) => {
  let finalTag: Tag | null = null;
  machinesByCategory.forEach((machine) => {
    const filteredTag: Tag = machine.category.find((tag: Tag) => {
      return tag.slug === key;
    });
    finalTag = filteredTag;
  });
  return finalTag;
};

export interface CategoryListItem {
  list: Machine[];
  title: string;
  slug: string;
}

/**
 *
 */
export const getCategoryList = (machines: Machines) => {
  const hash = machines.hash;
  const keys = Object.keys(hash);
  const catList: CategoryListItem[] = keys.map((key) => {
    const machinesByCategory = hash[key];
    const tag = getFinalTag(key, machinesByCategory);
    return {
      list: hash[key],
      title: tag.name,
      slug: tag.slug
    };
  });
  catList.unshift({ title: "All", slug: "all", list: machines.list });
  return catList;
};

/**
 * Get a list of Machine objects given a list of all categories
 */
export const getCategoryListItems = (
  catList: { list: Machine[]; title: string; slug: string }[],
  currentCat: string
) => {
  const catListHash = catList.reduce((hash, cat) => {
    if (!hash[cat.slug]) {
      hash[cat.slug] = cat.list;
    }
    return hash;
  }, {} as Record<string, Machine[]>);
  const thisCat = catListHash[currentCat];
  return thisCat;
};
