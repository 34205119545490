import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Machine, Machines } from "../../../classes/Machine";
import { Table } from "react-bootstrap";
import { Typography } from "../../ui/Typography";
import { colors } from "../../ui/variables";
import { CategoryListItem, getCategoryList } from "../../ui/Equipment/logic";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { useState, useEffect } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { MachinesQuery } from "../../../fragments/NodeMachinesFields";

const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 5rem;
  .applications {
    text-align: center;
    vertical-align: middle;
    border: 0px solid ${colors.silver.hex};
    border-bottom-width: 0px !important;
    border-right-width: 1px;
    border-left-width: 1px;
  }

  .checks {
    text-align: center;
    vertical-align: middle;
  }
  td {
    border: 0px solid ${colors.silver.hex};
    border-bottom-width: 0px !important;
    border-right-width: 1px;
    border-left-width: 1px;
    vertical-align: middle;
  }
  tbody {
    border-top-width: 0px !important;
  }
  th {
    border-bottom-width: 0px !important;
  }
  .downloadButton {
    text-align: center;
  }
  @media only screen and (max-width: 1270px) {
    .applications > div {
      font-size: 12px !important;
    }
    h4 {
      font-size: 1.5rem !important;
    }
  }
  @media only screen and (max-width: 450px) {
    h4 {
      font-size: 1.5rem !important;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem;
  // overflow: auto;
  @media only screen and (max-width: 740px) {
    margin: 4rem 0 0 0;
    table {
      margin-bottom: 3rem;
    }
  }
`;

/**
 *
 */
const ManualsTable = () => {
  const machinesData: {
    m: { edges: { node: MachinesQuery }[] };
  } = useStaticQuery(query);
  const machines = new Machines(machinesData.m.edges);
  let machinesByCategory = getCategoryList(machines);
  machinesByCategory.shift();
  machinesByCategory.sort(function (a, b) {
    if (a.title < b.title) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    } else {
      return 0;
    }
  });

  const [containerWidth, setContainerWidth] = useState(1201);

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setContainerWidth]);

  const CategoryTable = ({
    category,
    applicationColumns,
    machineManualRow
  }: {
    category: CategoryListItem;
    applicationColumns: string[];
    machineManualRow: string[];
  }) => (
    <StyledTable>
      <thead>
        <tr>
          <th>
            <Typography.H4 style={{ color: colors.blue.hex }}>
              {category.title}
            </Typography.H4>
          </th>
          {containerWidth > 1025 &&
            applicationColumns.map((a, i) => (
              <CatTableHeader application={a} key={a + i} />
            ))}
        </tr>
      </thead>
      <tbody>
        {machineManualRow.map((machineValue, i) => (
          <MachineManualRow
            machineAppValue={machineValue}
            key={machineValue + i}
            i={i}
            machine={category.list[i]}
          />
        ))}
      </tbody>
    </StyledTable>
  );

  const CatTableHeader = ({ application }: { application: string }) => (
    <th
      style={{
        background: colors.veryLightBlue.hex
      }}
      className="applications">
      <Typography.P style={{ fontWeight: "600" }}>{application}</Typography.P>
    </th>
  );

  const CheckBoxTd = ({
    truthValue,
    i
  }: {
    truthValue?: string;
    i: number;
  }) => (
    <td
      style={{
        background: i % 2 ? colors.veryLightBlue.hex : colors.lightBlue.hex
      }}
      className="checks"
      key={truthValue + i}>
      {truthValue && <FaCheck style={{ padding: 0, color: colors.blue.hex }} />}
    </td>
  );

  const machineTitleDisplay = (machine: Machine) =>
    machine.manual && containerWidth > 1025 ? (
      <WmkLink target={"_blank"} to={machine.manual.file.url}>
        <Typography.P>{machine.displayTitle}</Typography.P>
      </WmkLink>
    ) : (
      <Typography.P>{machine.displayTitle}</Typography.P>
    );

  const DownloadButtonDisplay = ({ machine }: { machine: Machine }) => {
    return machine.manual ? (
      <WmkLink target={"blank"} to={machine.manual.file.url}>
        <Typography.H2>
          <AiOutlineDownload />
        </Typography.H2>
      </WmkLink>
    ) : (
      <Typography.H2 style={{ color: colors.silver.hex }}>
        <AiOutlineDownload />
      </Typography.H2>
    );
  };

  const MachineManualRow = ({
    machineAppValue,
    machine,
    i
  }: {
    machineAppValue: string;
    machine: Machine;
    i: number;
  }) => (
    <tr
      style={{
        background: i % 2 ? colors.veryLightBlue.hex : colors.lightBlue.hex
      }}
      key={machine.displayTitle + i}>
      <td key={machine.displayTitle + i} className="titleDisplay">
        {machineTitleDisplay(machine)}
      </td>
      {containerWidth > 1025 &&
        Array.isArray(machineAppValue) &&
        machineAppValue.map((appValue, j) => (
          <CheckBoxTd
            truthValue={appValue}
            i={i}
            key={appValue + machine.displayTitle + j}
          />
        ))}
      {containerWidth < 1026 && containerWidth > 850 && (
        <td className="downloadButton col-1">
          <DownloadButtonDisplay machine={machine} />
        </td>
      )}
      {containerWidth < 851 && (
        <td className="downloadButton col-2">
          <DownloadButtonDisplay machine={machine} />
        </td>
      )}
    </tr>
  );

  // create table JSX array of all category tables
  const catTables = machinesByCategory.reduce((hash, cat) => {
    cat.list.sort(function (a, b) {
      if (a.displayTitle < b.displayTitle) {
        return -1;
      } else if (a.displayTitle > b.displayTitle) {
        return 1;
      } else {
        return 0;
      }
    });
    // create array of all applications within a given category set
    const catAppList: string[] = cat.list.reduce((apps: string[], machine) => {
      const applist: string[] = machine.applications;
      if (Array.isArray(applist)) {
        return [...new Set<string[]>([...apps, ...applist])];
      }
      return apps;
    }, []);

    // create an array of arrays containing booleans, the parent arrray is the machine and the child is if each app is serviced by that machine
    const machineAppValue: string[] = cat.list.reduce((apps, machine) => {
      const applist = machine.applications;
      if (Array.isArray(catAppList)) {
        const appValue: string[] = catAppList.reduce((value, app) => {
          if (Array.isArray(applist) && applist.includes(app)) {
            return [...value, true];
          } else {
            return [...value, false];
          }
        }, []);
        return [...apps, appValue];
      }
      return apps;
    }, []);
    // push table JSX into array of all category tables
    hash.push(
      <CategoryTable
        category={cat}
        applicationColumns={catAppList}
        machineManualRow={machineAppValue}
        key={cat.title}
      />
    );

    return hash;
  }, []);

  return <StyledDiv>{catTables}</StyledDiv>;
};

export default ManualsTable;

const query = graphql`
  {
    m: allContentfulMachines {
      edges {
        node {
          ...NodeMachinesFields
        }
      }
    }
  }
`;
