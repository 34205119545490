import * as React from "react";
import { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import ServicesHeroNavPiece from "./ServicesHeroNavPiece";
import { MenuFields } from "../../../../fragments/NodeMenuFields";
import { PageQuery } from "../../../../fragments/NodePageFields";

const stackBreak = "991px";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: -15rem;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  @media only screen and (max-width: ${stackBreak}) {
    flex-direction: column;
    margin-top: -8rem;
  }
`;

const ServicesHeroNav = () => {
  const [currPageInd, setCurrPageInd] = useState(0);
  const servicePagesMenu: MenuFields = useStaticQuery(query).menu;
  const servicePages = servicePagesMenu.links;

  return (
    <Wrap>
      {servicePages.map((page, i) => (
        <ServicesHeroNavPiece
          page={page as PageQuery}
          index={i}
          currPageInd={currPageInd}
          setCurrPageInd={setCurrPageInd}
          stackBreak={stackBreak}
          key={`${page.title} + ${i}`}
        />
      ))}
    </Wrap>
  );
};

export default ServicesHeroNav;

const query = graphql`
  query {
    menu: contentfulMenu(title: { regex: "/services menu/i" }) {
      ...NodeMenuFields
    }
  }
`;
