import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { colors } from "../../../ui/variables";
import { Typography } from "../../../ui/Typography";
import { WmkLink } from "wmk-link";
import { PageQuery } from "../../../../fragments/NodePageFields";

const Wrap = styled.div<{ stackBreak: string }>`
  position: relative;
  height: 45rem;
  width: 20%;
  transition: width 0.3s ease;
  &.active {
    width: 40%;
    transition: width 0.3s ease;
  }
  @media only screen and (max-width: ${({ stackBreak }) =>
      stackBreak ? stackBreak : "991px"}) {
    width: 100% !important;
  }
`;
const ImgWrap = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
const DarkenOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.8) 80%
  );
  @media only screen and (max-width: 991px) {
    background: rgba(0, 0, 0, 0.5);
  }
`;
const ContentWrap = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10% 10% 2rem;
  * {
    color: ${colors.white.hex};
  }
  a {
    display: inline-block !important;
    margin-top: 1rem;
    text-decoration: none;
    &:hover {
      p {
        background: linear-gradient(to bottom, #2a7de1, #2367ba) !important;
      }
    }
  }
`;
const InnerContentWrap = styled.div<{ stackBreak: string }>`
  position: relative;
  z-index: 1;
  left: 0;
  width: auto;
  height: auto;
  opacity: 1;
  transition: z-index 0.1s ease, left 0.1s ease, width 0.1s ease,
    height 0.1s ease, opacity 0.3s ease 0.3s;
  @media only screen and (max-width: ${({ stackBreak }) =>
      stackBreak ? stackBreak : "991px"}) {
    bottom: 0;
  }
`;
const InactiveTitleWrap = styled.div<{ stackBreak: string }>`
  position: relative;
  height: 100%;
  transform: rotate(-90deg);
  white-space: nowrap;
  word-spacing: 5px;
  bottom: 10vw;
  left: 45%;
  height: 0;
  opacity: 0;
  &.active {
    display: none;
  }
  @media only screen and (max-width: ${({ stackBreak }) =>
      stackBreak ? stackBreak : "991px"}) {
    transform: rotate(0deg);
    width: 100%;
    // text-align: center;
    left: 0;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ServicesHeroNavPiece = ({
  page,
  index,
  currPageInd,
  setCurrPageInd,
  stackBreak
}: {
  page: PageQuery;
  index: number;
  currPageInd: number;
  setCurrPageInd: React.Dispatch<React.SetStateAction<number>>;
  stackBreak: string;
}) => {
  const image = page.featuredImage;
  const gatsby = image.gatsbyImageData;
  const alt = image.title;
  const title = page.pageTitle;
  const description = page.header.text;
  const slug = page.slug;
  const parentPath = page.parentPath;
  const fullPath =
    parentPath && slug ? `${parentPath}/${slug}` : slug ? `/${slug}` : `#`;
  return (
    <Wrap
      stackBreak={stackBreak}
      className={index === currPageInd ? "active" : ""}
      onMouseEnter={() => setCurrPageInd(index)}>
      <ImgWrap>
        <GatsbyImage image={gatsby} alt={alt} />
        <DarkenOverlay />
      </ImgWrap>
      <ContentWrap>
        <InactiveTitleWrap
          className={index !== currPageInd ? "active" : ""}
          stackBreak={stackBreak}>
          <Typography.H5>{title}</Typography.H5>
        </InactiveTitleWrap>
        <InnerContentWrap
          className={index === currPageInd ? "active" : ""}
          stackBreak={stackBreak}>
          <Typography.H5>{title}</Typography.H5>
          <Typography.P style={{ maxWidth: "1000px" }}>
            {description}
          </Typography.P>
          <WmkLink to={fullPath}>
            <Typography.Button
              style={{
                padding: "5px 15px",
                background: colors.blue.hex,
                marginBottom: 0
              }}>
              LEARN MORE
            </Typography.Button>
          </WmkLink>
        </InnerContentWrap>
      </ContentWrap>
    </Wrap>
  );
};

export default ServicesHeroNavPiece;
